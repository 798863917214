import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const SecondChance = () => {
  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          lang="en"
          title="Second Chance Program"
          description="It is time for a brand new, healthy, beautiful smile."
        />

        <div className="sc-program">
          <div className="sc__hero">
            <div className="sc__hero--desktop">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/v1659042618/Programs/second-chance-desktop-hero-image.jpg"
                alt="program banner"
              />
            </div>
            <div className="sc__hero--mobile">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/v1659042617/Programs/second-chance-mobile-hero-image.jpg"
                alt="program banner"
              />
            </div>
          </div>

          <div className="sc__section">
            <div className="">
              <div className="sc__about-grid--content">
                <p className="sc__subheading">
                  A Second Chance for a Healthy Smile{" "}
                </p>
                <br />
                <p>
                  Beacon Oral & Maxillofacial Surgeons is proud to announce its
                  fifth Second Chance program. This life-changing program
                  provides one deserving recipient who lives in or near Gresham,
                  The Dalles, Milwaukie, OR, or Camas, WA, with a complete smile
                  makeover free of charge.
                </p>
                <br />
                <p>
                  The ideal candidate has multiple missing or failing teeth and
                  cannot afford restorative treatment. After the submission
                  period, we will select one recipient to receive a free{" "}
                  <Link
                    to="/oral-surgery-procedures/diem-full-arch-restoration/"
                    title="Learn more about full-arch restoration"
                  >
                    full-arch restoration
                  </Link>{" "}
                  procedure. We encourage anyone in our community who is
                  dreaming of a healthy, beautiful new smile to apply!
                </p>
                <br />
                <p className="sc__subheading">Full-Arch Restoration</p>
                <br />
                <p>
                  Full-arch restoration is a healthy and effective way to
                  replace many missing teeth. The procedure works by securing a
                  full prosthesis in the mouth using as few as four dental
                  implants. The dental implant posts will permanently hold your
                  new teeth in place while also preventing bone loss.{" "}
                </p>
              </div>
              <div className="sc__about-grid--info">
                <div className="sc__brackets sc__brackets--desktop">
                  <div>
                    <p className="sc__subheading">How To Apply</p>
                    <ul>
                      <li>Complete the short application.</li>
                      <li>Upload photos of your smile and teeth.</li>
                      <li>Submit your application by October 6, 2023.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sc__section">
            <p>
              <b>
                Thank you for your interest in the Second Chance program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </b>
            </p>
            <p>
              Stay tuned to this page and our{" "}
              <a
                href="https://www.facebook.com/Beaconoms"
                target="_blank"
                title="Follow us on Facebook"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/beaconoralsurgeons/"
                target="_blank"
                title="Follow us on Instagram"
              >
                Instagram
              </a>{" "}
              page for program updates!
            </p>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/oral-surgery-procedures/dental-implants-gresham-oregon/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/oral-surgery-procedures/diem-full-arch-restoration/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
